import React from "react";
import { data } from "../../constants";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { motion } from "framer-motion";
import { FaPhoneAlt, FaEnvelope } from "react-icons/fa";
import { BsInstagram, BsWhatsapp, BsLinkedin } from "react-icons/bs";

import "./Menu.css";

const Menu = () => {
  return (
    <motion.div
      initial={{ y: -100, x: "0%", opacity: 0 }}
      animate={{ y: 0, x: "0%", opacity: 1 }}
    >
      {" "}
      <div
        className="banner"
        style={{
          backgroundColor: "#DB3349",
          color: "white",
          textAlign: "center",
          padding: "10px 20px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <a
              style={{ color: "white" }}
              target="_blank"
              rel="noreferrer"
              href="https://www.instagram.com/grow.throcket/"
            >
              <BsInstagram />
            </a>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <a
              style={{ color: "white" }}
              target="_blank"
              rel="noreferrer"
              href="https://api.whatsapp.com/send/?phone=917489748035&text=Hello%2C+I+am+interested+in+learning+more+about+your+business&type=phone_number&app_absent=0"
            >
              <BsWhatsapp />
            </a>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <a
              style={{ color: "white" }}
              target="_blank"
              rel="noreferrer"
              href="https://www.linkedin.com/company/grow-throcket/"
            >
              <BsLinkedin />
            </a>
          </div>
          <div>
            {" "}
            <a
              style={{ textDecoration: "none", color: "white" }}
              href="tel:+91-7489748035"
            >
              <FaPhoneAlt /> &nbsp; +91-7489748035
            </a>
          </div>
          <div>
            <a
              style={{ textDecoration: "none", color: "white" }}
              href="mailto:hey@growthrocket.xyz"
            >
              <FaEnvelope /> &nbsp; hey@growthrocket.xyz
            </a>
          </div>
        </div>
      </div>
      <Navbar collapseOnSelect expand="lg" bg="ligth" variant="ligth">
        <Container>
          <Navbar.Brand href="/#home">
            <h1
              className="heading-fix"
              style={{
                fontSize: "50px",
                textDecorationColor: "#DB3349",
                textDecorationLine: "underline",
              }}
            >
              Growth Rocket
            </h1>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse
            id="responsive-navbar-nav"
            className="justify-content-end"
          >
            <Nav>
              {data.Menu.map((item, index) => (
                <Nav.Link key={index} href={item.link}>
                  {item.text}
                </Nav.Link>
              ))}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </motion.div>
  );
};

export default Menu;
