import React from "react";
import "./Choose.css";
import { Headings } from "../../components";

function Choose() {
  return (
    <div className="why">
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Headings
          title="Why Choose Us"
          text="We offer the best services in the industry"
        />
      </div>
      <table className="why-choose-us">
        <thead>
          <tr>
            <th>What's Different</th>
            <th>Growth Rocket</th>
            <th>Typical Agencies</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <ul>
                <li>In-House Expertise</li>
                <li>Custom Strategies</li>
                <li>Focused Service</li>
                <li>Transparent Costs</li>
                <li>Industry Knowledge</li>
                <li>Track Record</li>
                <li>Recognition</li>
                <li>Long-Term Growth</li>
              </ul>
            </td>
            <td className="blue-underline">
              <ul>
                <li>
                  <span>&#10003;</span>We work in-house ensuring quality and
                  service
                </li>
                <li>
                  <span>&#10003;</span> Personalized strategies based on your
                  unique goals
                </li>
                <li>
                  <span>&#10003;</span> Personal, dedicated service for every
                  client
                </li>
                <li>
                  <span>&#10003;</span> Fees are upfront, and you own all your
                  data
                </li>
                <li>
                  <span>&#10003;</span> Years of experience working for various
                  industries
                </li>
                <li>
                  <span>&#10003;</span> Proven success in helping businesses
                  over 10+ projects
                </li>
                <li>
                  <span>&#10003;</span> Acknowledged as a top agency by industry
                  leaders
                </li>
                <li>
                  <span>&#10003;</span> We focus on building lasting
                  relationship with our clients
                </li>
              </ul>
            </td>
            <td>
              <ul>
                <li>
                  <span>&#10007;</span> Work may be outsourced, affecting
                  consistency and quality
                </li>
                <li>
                  <span>&#10007;</span> Use a standard approach for all clients
                </li>
                <li>
                  <span>&#10007;</span>
                  Service can have less personal attention to individual clients
                </li>
                <li>
                  <span>&#10007;</span>Costs cvan be unclear with potential for
                  unexpected fees
                </li>
                <li>
                  <span>&#10007;</span>Some agencies might limit their serivce
                  to diverse businesses
                </li>
                <li>
                  <span>&#10007;</span>Results may not be as well documented or
                  proven
                </li>
                <li>
                  <span>&#10007;</span>
                  May lack formal recognition or accolades in the industry
                </li>
                <li>
                  <span>&#10007;</span> The focus may be more on short-term
                  projects
                </li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default Choose;
