import React from "react";
import "./CTA.css";
import { motion } from "framer-motion";
import { images } from "../../constants";

const CTA = () => {
  return (
    <motion.div
      initial={{
        x: "0%",
        y: -100,
        opacity: 0,
      }}
      whileInView={{
        x: "0%",
        y: 0,
        opacity: 1,
      }}
      transition={{
        duration: 1,
        delay: 0.6,
      }}
      viewport={{ once: true }}
      className="section-padding"
    >
      <div className="row cta">
        <div className="col-lg-8 col-12">
          <h3>Let’s make things happen</h3>

          <p className="pt-2 pb-4">
            Contact us today to learn more about how our digital marketing
            services can help your business grow and succeed online.
          </p>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://api.whatsapp.com/send/?phone=917489748035&text=Hello%2C%20I%20am%20interested%20in%20learning%20more%20about%20your%20business.%20Could%20you%20please%20provide%20me%20with%20a%20proposal%20outlining%20your%20services%2C%20pricing%2C%20and%20any%20additional%20information%20that%20would%20help%20me%20understand%20how%20we%20might%20collaborate%3F&type=phone_number&app_absent=0"
          >
            <button className="btn-positivus">Get your free proposal</button>
          </a>
        </div>
        <div className="col-lg-4 d-lg-flex d-none">
          <img src={images.thingshappen} alt="thingshappen" />
        </div>
      </div>
    </motion.div>
  );
};

export default CTA;
