import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/react";
import { FaWhatsapp } from "react-icons/fa";

import {
  Testimonials,
  CaseStudies,
  Contact,
  Footer,
  Hero,
  Process,
  Team,
  Services,
  Slider,
  Slider1,
  CTA,
  Partners,
  Study,
  DynamicPage,
  Choose,
} from "./container";
import { Menu } from "./components";

const App = () => (
  <Router>
    <div className="container">
      <Analytics />
      <SpeedInsights />
      <Menu />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/:slug" element={<DynamicPage />} />
        {/*create a route if no route is matched it shows 404 not found */}
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
      <Footer />
    </div>
  </Router>
);

const NotFoundPage = () => {
  return <h1>404 Not Found</h1>;
};

const Home = () => (
  <>
    <Hero />
    <Slider />
    <Services />
    <Team />
    <CTA />
    <Partners />
    <CaseStudies />
    <Process />
    <Study />
    <Choose />
    {/*   <VideoReview /> */}
    <Testimonials />
    <Slider1 />
    <Contact />
    <div style={whatsappButtonStyle}>
      <a href="https://api.whatsapp.com/send/?phone=917489748035&text=Hello%2C+I+am+interested+in+learning+more+about+your+business&type=phone_number&app_absent=0">
        <FaWhatsapp style={iconStyle} />
      </a>
    </div>
  </>
);
const whatsappButtonStyle = {
  position: "fixed",
  bottom: "30px",
  right: "30px",
  backgroundColor: "#DB3349",
  padding: "12px",
  borderRadius: "50%",
  zIndex: 1000,
};

const iconStyle = {
  color: "white",
  fontSize: "45px",
};

export default App;
