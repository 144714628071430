import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import data from "../constants/data";
import { motion } from "framer-motion";
import CTA from "./CTA/CTA";
import Partners from "./partners/Partners";
import Contact from "./Contact/Contact";
import "./Dynamic.css";

const DynamicPage = () => {
  const [pageData, setPageData] = useState(null);
  const { slug } = useParams();

  useEffect(() => {
    const page = data.DyanmicPages.find((page) => page.slug === slug);
    setPageData(page);
    if (page) {
      document.title = `Growth Rocket | ${page.title}`;
    }
  }, [slug]);

  if (!pageData)
    return (
      <center>
        <h1>404 - Page Not Found</h1>
      </center>
    );
  console.log(pageData);
  return (
    <motion.div
      initial={{ y: -100, x: "0%", opacity: 0 }}
      animate={{ y: 0, x: "0%", opacity: 1 }}
      transition={{ delay: 0.3 }}
    >
      <div className="row align-items-center">
        <div className="col-md-6 col-12">
          <h1 className="title">
            {pageData.title.charAt(0).toUpperCase() + pageData.title.slice(1)}
          </h1>
          <p style={{ lineHeight: "30px" }} className="py-4">
            {pageData.content}
          </p>
        </div>
        <div className="col-md-6 col-12 mt-md-0 mt-4">
          <img className="img-fluid" src={pageData.image} alt="design" />
        </div>
      </div>
      <div className="row align-items-center">
        <div className="col">
          {pageData.paragraphs.map((paragraph, index) => (
            <p key={index} style={{ lineHeight: "30px" }} className="py-4">
              {paragraph.content}
            </p>
          ))}
          {pageData.list && (
            <>
              {pageData.list.map((item, index) => (
                <p style={{ lineHeight: "30px" }} key={index}>
                  <strong>{item.Bold}</strong> {item.content}
                </p>
              ))}
            </>
          )}
          {pageData.Seller && (
            <center style={{ paddingBottom: "20px" }}>
              <h3
                style={{
                  textDecoration: "underline",
                  textDecorationColor: "#1AB5DB",
                }}
              >
                {pageData.Seller}
              </h3>
            </center>
          )}
          <div className="row">
            {pageData.Cards &&
              pageData.Cards.map((card, index) => (
                <div className="col-lg-4 col-md-6 col-12" key={index}>
                  <div className="card-team">
                    <div className="card-team-header">
                      {/* Assuming 'foto' and 'name' are properties of 'card' */}
                      {/* <img src={card.foto} alt={card.name} /> */}
                      <p>
                        <span>{card.title}</span>
                      </p>
                    </div>
                    {/* Assuming 'image' property is present in the card data */}
                    <center>
                      <img
                        width="80px"
                        style={{ paddingBottom: "15px" }}
                        src={card.img}
                        alt=""
                      />{" "}
                      <br />
                      <a
                        style={{
                          textDecoration: "underline",
                          color: "black",
                          fontSize: "18px",
                        }}
                        href={`https://api.whatsapp.com/send/?phone=917489748035&text=Hello%2C+I+am+interested+in+learning+about+selling+on+${encodeURIComponent(
                          card.title
                        )}&type=phone_number&app_absent=0`}
                      >
                        <strong>Sell Now</strong>
                      </a>
                    </center>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>

      <CTA />
      <Partners />
      <Contact />
    </motion.div>
  );
};

export default DynamicPage;
