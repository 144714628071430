import React from "react";
import { motion } from "framer-motion";
import "./Study.css";
import { Headings } from "../../components";
import hero from "../../assets/hero.png";
import video from "../../assets/Review.mp4";

function Study() {
  return (
    <motion.div
      initial={{
        x: "0%",
        y: -100,
        opacity: 0,
      }}
      whileInView={{
        x: "0%",
        y: 0,
        opacity: 1,
      }}
      transition={{
        duration: 1,
        delay: 0.7,
      }}
      viewport={{ once: true }}
    >
      <div className="row align-items-center">
        <Headings
          title="Case Study"
          text={"Learn how we helped our clients increase their ROAS"}
        />
        <div className="col-md-6 col-12">
          <h1 className="title">
            Revolutionizing launches: 5.17X ROAS, 300 monthly orders.
          </h1>
          <p style={{ lineHeight: "30px" }} className="py-4">
            Experience groundbreaking success with our dual-strategy approach,
            propelling nationwide brand launches to new heights. Witness the
            power of organic and paid channels harmoniously driving 5.17X ROAS
            and 300 monthly orders. Unleash the full potential of Email
            Marketing, SEO enhancements, and targeted campaigns across major
            platforms for unparalleled results.
          </p>
          <a
            target="_blank"
            rel="noreferrer"
            download={true}
            href="/Growth Rocket Case Study.pdf"
          >
            <button className="btn-positivus">Download Case Study</button>
          </a>
        </div>
        <div className="col-md-6 col-12 mt-md-0 mt-4">
          <video
            className="review"
            src={video}
            width="600"
            height="500"
            controls
            style={{
              maxWidth: "100%",
              height: "500px",
              display: "block",
              margin: "0 auto",
            }}
          />
        </div>
      </div>
    </motion.div>
  );
}

export default Study;
