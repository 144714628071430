import React from "react";
import "./Footer.css";
import { data } from "../../constants";
import { BsInstagram, BsWhatsapp, BsLinkedin } from "react-icons/bs";
import { motion } from "framer-motion";

const Footer = () => {
  return (
    <motion.div
      initial={{
        opacity: 0,
      }}
      whileInView={{
        opacity: 1,
      }}
      transition={{
        duration: 1,
      }}
      viewport={{ once: true }}
    >
      <footer>
        <div className="row align-items-center">
          <div className="col-md-2 col-12">
            <h1
              style={{
                fontSize: "40px",
                textDecorationColor: "#DB3349",
                textDecorationLine: "underline",
              }}
            >
              Growth Rocket
            </h1>
          </div>
          <div className="col-md-8 col-12 ">
            <ul className="navbar-footer">
              {data.Menu.slice(0, -1).map((item, index) => (
                <li key={item.text}>
                  {" "}
                  <a href={item.link}>{item.text}</a>
                </li>
              ))}
            </ul>
          </div>
          <div className="col-md-2 col-12 text-center text-md-end fs-4">
            <a
              style={{ color: "#1AB5DB" }}
              target="_blank"
              rel="noreferrer"
              href="https://www.instagram.com/grow.throcket/"
            >
              <BsInstagram />
            </a>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <a
              style={{ color: "#1AB5DB" }}
              target="_blank"
              rel="noreferrer"
              href="https://api.whatsapp.com/send/?phone=917489748035&text=Hello%2C+I+am+interested+in+learning+more+about+your+business&type=phone_number&app_absent=0"
            >
              <BsWhatsapp />
            </a>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <a
              style={{ color: "#1AB5DB" }}
              target="_blank"
              rel="noreferrer"
              href="https://www.linkedin.com/company/grow-throcket/"
            >
              <BsLinkedin />
            </a>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 col-12">
            <ul className="info-contact">
              <li>
                {" "}
                <span>Vision:</span>
              </li>
              <li>
                We're your all-in-one solution for navigating the digital
                landscape and drive success for your brand.
              </li>
            </ul>
          </div>
          <div className="col-md-4 col-12">
            <ul className="info-contact">
              <li>
                {" "}
                <span>Contact us:</span>
              </li>
              <li>
                Email:{" "}
                <a
                  style={{ textDecoration: "none", color: "white" }}
                  href="mailto:hey@growthrocket.xyz"
                >
                  hey@growthrocket.xyz
                </a>
              </li>
              <li>
                Phone:{" "}
                <a
                  style={{ textDecoration: "none", color: "white" }}
                  href="tel:+91-7489748035"
                >
                  +91-7489748035
                </a>
              </li>
            </ul>
          </div>
          <div className="col-md-4 col-12">
            <ul className="info-contact">
              <li>
                {" "}
                <span>Address:</span>
              </li>
              <li>
                B-82, Shree Krishna Kripa kunj, Samdhiya Colony, Gwalior
                474001, Madhya Pradesh
              </li>
            </ul>
          </div>
          {/*  <div className="col-md-8 col-12">
          <form className="row form-news">
            <div className="col-lg-6 col-12">
              <input
                type="email"
                className="form-control"
                id="email"
                placeholder="Email"
              />
            </div>
            <div className="col-lg-6 col-12 mt-3 mt-lg-0">
              <button type="submit" className="btn-positivus w-100">
                Subscribe to news
              </button>
            </div>
          </form>
        </div> */}

          <div className="rights">
            <p>© 2024 Growth Rocket. All Rights Reserved.</p>
          </div>
        </div>
      </footer>
    </motion.div>
  );
};

export default Footer;
