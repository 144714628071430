import images from "./images";

const Menu = [
  {
    text: "Services",
    link: "/#services",
  },
  {
    text: "Projects",
    link: "/#projects",
  },
  {
    text: "Process",
    link: "/#process",
  },

  {
    text: "Testimonials",
    link: "/#testimonials",
  },
  {
    text: "Blogs",
    link: "/",
  },
  {
    text: "Request a quote",
    link: "/#quote",
  },
];
const ServicesData = [
  {
    titleone: "Marketplace",
    titletwo: "Management",
    link: "/marketplace-management",
    itemclass: "blight",
    imgURL: images.services01,
  },
  {
    titleone: "Web Design &",
    titletwo: "Development",
    link: "/web-design-and-development",
    itemclass: "bgreen",
    imgURL: images.services02,
  },
  {
    titleone: "Social Media",
    titletwo: "Management",
    link: "/social-media-management",
    itemclass: "bdark",
    imgURL: images.services03,
  },
  {
    titleone: "Email",
    titletwo: "Marketing",
    link: "/email-marketing",
    itemclass: "blight",
    imgURL: images.services04,
  },
  {
    titleone: "Lead",
    titletwo: "Generation",
    link: "/lead-generation",
    itemclass: "bgreen",
    imgURL: images.services05,
  },
  {
    titleone: "Ads",
    titletwo: "Management",
    link: "/ads-management",
    itemclass: "bdark",
    imgURL: images.services06,
  },
];
const CaseStudies = [
  {
    text: "For Xtreme Tattoo Studio, we revamped their website from WordPress to React, increasing traffic by 50% and improving user experience.",
    link: "https://xtremetattoos.co.in/",
  },
  {
    text: "For BeSpace, we designed a responsive multipage website with smooth transitions to showcase their interior design works.",
    link: "https://www.bespace.in/",
  },
  {
    text: "For HueSoul Management Pvt Ltd, we developed a React website to showcase all their awards, boosting their online presence and services.",
    link: "https://www.huesoul.in/",
  },
];

const WorkingProcess = [
  {
    title: "Consultation",
    description:
      "During the initial consultation, we will discuss your business goals and objectives, target audience, and current marketing efforts. This will allow us to understand your needs and tailor our services to best fit your requirements.",
  },
  {
    title: "Research and Strategy Development",
    description:
      "Our team will conduct thorough research on your industry, competitors, and target market to formulate a comprehensive strategy tailored to your business goals. We will identify key opportunities and create a roadmap for success.",
  },
  {
    title: "Implementation",
    description:
      "With a solid strategy in place, we will proceed with the implementation phase. This involves executing various marketing tactics such as content creation, advertising campaigns, and social media management to reach your target audience effectively.",
  },
  {
    title: "Monitoring and Optimization",
    description:
      "We continuously monitor the performance of our marketing efforts and analyze key metrics to identify areas for improvement. Through constant optimization and adjustment, we ensure that your campaigns are delivering the best possible results.",
  },
  {
    title: "Reporting and Communication",
    description:
      "Transparency is key to our partnership. We provide regular reports detailing the progress of your marketing campaigns, along with insights and recommendations for future strategies. Additionally, we maintain open lines of communication to address any questions or concerns you may have.",
  },
  {
    title: "Continual Improvement",
    description:
      "Our commitment to your success doesn't end with the completion of a campaign. We believe in continual improvement and strive to refine our strategies based on ongoing feedback and market trends. By staying agile and adaptable, we ensure that your business remains ahead of the competition.",
  },
];

const Team = [
  {
    name: "Struggling to reach your target audience?",
    info: "lets face it, finding the right marketing strategy can be tough. But with over 10 years of experience in digital marketing, We can help you reach your target audience and grow your business. Lets work together to create a marketing strategy that works for you.",
    foto: images.team01,
  },
  {
    name: "Unsure About Your Marketing ROI?",
    info: "Investing in marketing is vital, but it's frustrating when you don't see results. We can help you track your ROI and make data-driven decisions to improve your marketing strategy. Let's work together to grow your business. ",
    foto: images.team03,
  },
  {
    name: "Website Not Working for You?",
    info: "You've got a website, but is it actually working to bring in leads and sales? Is it attracting the right visitors and guiding them towards conversion?",
    foto: images.team04,
  },
];

const Testimonials = [
  {
    name: "Srinivas",
    position: "Xtreme Tattoo Studio",
    testimonial:
      "Growth Rocket revolutionized our online presence by transforming our website from WordPress to React. This change not only increased our traffic by 50% but also significantly improved user experience. With Quantech's expertise, we saw a boost in SEO rankings, making us more visible to potential clients.",
  },
  {
    name: "Suresh Kolkar",
    position: "Bespace Interior Design Studio",
    testimonial:
      "Growth Rocket work on our website exceeded our expectations. They designed a responsive multipage website that beautifully showcased our interior design works. The smooth transitions and SEO optimizations she implemented greatly improved user engagement and our search engine rankings.",
  },
  {
    name: "Mr M S Karthik",
    position: "HueSoul Management Pvt Ltd",
    testimonial:
      "Growth Rocket development of our React website was a game-changer, beautifully showcasing our awards and significantly boosting our online presence. Their SEO expertise helped us rank higher, attracting more potential clients. We're extremely satisfied and highly recommend Quantech for any web development needs.",
  },
  {
    name: "Shadan Ahmed",
    position: "Italian Eleganza",
    testimonial:
      "Growth Rocket has been a total game changer for italian Eleganza! They helped us get results, boosting our sales and giving us an impressive ROI. We highly recommend Growth Rocket for any business looking to grow. They managed our accounts on platforms like Amazon and flipkart.",
  },
];
const DyanmicPages = [
  {
    slug: "email-marketing",
    title: "Email Marketing: Boost Engagement & Drive Conversions",
    content:
      "Email marketing stands as a cornerstone of modern digital outreach, offering businesses a direct line of communication with their audience. Through strategic campaigns, companies can leverage this powerful tool to deliver personalized messages, nurture leads, and drive conversions. By harnessing the potential of email marketing, businesses can cultivate lasting relationships with their subscribers, fostering brand loyalty and propelling growth in an increasingly competitive marketplace.",
    image: images.email,
    paragraphs: [
      {
        content:
          "In today's digitally-driven world, email marketing remains a vital component of any comprehensive marketing strategy. Its versatility and effectiveness in reaching target audiences make it a powerful tool for businesses looking to connect with customers on a personal level. Through carefully crafted email campaigns, companies can deliver relevant content, promotions, and updates directly to subscribers' inboxes, fostering engagement and driving conversions. By leveraging segmentation, automation, and analytics, businesses can tailor their email communications to the unique preferences and behaviors of their audience, ensuring each interaction is meaningful and impactful.",
      },
      {
        content:
          "Moreover, email marketing offers unparalleled insights into customer behavior and preferences, allowing businesses to refine their strategies and optimize campaign performance over time. Whether it's welcoming new subscribers, re-engaging dormant customers, or nurturing leads through the sales funnel, email marketing offers endless opportunities for businesses to connect with their audience at every stage of the customer journey. With its ability to deliver personalized, timely, and relevant content, email marketing continues to be a cornerstone of successful digital marketing strategies, driving growth, and fostering long-lasting relationships with customers.",
      },
      {
        content:
          "At Growth Rocket, we specialize in creating data-driven email marketing campaigns that engage audiences, drive conversions, and deliver measurable results. Our team of experts works closely with clients to develop tailored strategies that align with their business goals and objectives, leveraging the latest tools and technologies to optimize performance and maximize ROI. Whether you're looking to increase brand awareness, drive website traffic, or boost sales, our email marketing services can help you achieve your marketing objectives and grow your business.",
      },
    ],
  },

  {
    slug: "marketplace-management",
    title: "Marketplace Management: Streamline Your Operations",
    content:
      "Marketplace management is a crucial aspect of running a successful e-commerce business. By effectively managing your online marketplace presence, you can streamline operations, optimize sales, and enhance customer satisfaction. From product listing optimization to inventory management and order fulfillment, effective marketplace management strategies can help you maximize your revenue potential and grow your business.",
    image: images.marketplace,
    paragraphs: [
      {
        content:
          "At Growth Rocket, we specialize in helping businesses optimize their marketplace management processes to drive growth and profitability. Our team of experts works closely with clients to develop tailored strategies that address their unique challenges and objectives. Whether you're looking to expand your product offerings, improve your visibility on online marketplaces, or streamline your operations for greater efficiency, our marketplace management services can help you achieve your goals.",
      },
    ],
    list: [
      {
        Bold: "Complete Seller Account Management:",
        content:
          " We will oversee all aspects of managing your seller accounts on Amazon and Flipkart, including product listings, inventory management, order processing, and customer support, ensuring smooth operations and efficient performance.",
      },
      {
        Bold: "SEO for All Products:",
        content:
          "Conducting thorough keyword research and optimizing product listings to enhance search engine visibility and drive organic traffic, resulting in increased sales and conversions on both platforms.",
      },
      {
        Bold: "Advertisement Creation & Optimization:",
        content:
          "Crafting compelling and targeted advertisements for your products on both platforms to maximize exposure and generate relevant traffic to your listings.",
      },
      {
        Bold: "New Product Listing:",
        content:
          "Assisting in creating engaging product listings for new products, including crafting persuasive titles and bullet points that highlight key features and benefits.",
      },
      {
        Bold: "A+ Listing Creation:",
        content:
          "Designing and implementing A+ listings with rich content to improve customer engagement and boost conversion rates.",
      },
      {
        Bold: "Approvals, Program Enrolment, and Basic Setup:",
        content:
          "Handling necessary approvals, program enrollments, and basic setup procedures to ensure full operational compliance with platform guidelines.",
      },
      {
        Bold: "Premium Keyword Research:",
        content:
          "Conducting in-depth keyword research to identify high-demand, low-competition keywords for optimizing product listings.",
      },
      {
        Bold: "SEO & Rank Tracking:",
        content:
          " Continuously monitoring product rankings on search results pages to identify areas for improvement and enhance visibility and organic ranking.",
      },
      {
        Bold: "FBA Inventory Management:",
        content:
          " Efficiently managing FBA inventory on Amazon and handling inventory management requirements on Flipkart. ",
      },
      {
        Bold: "Cases, Claims, Reimbursement:",
        content:
          "Managing all cases, claims, and reimbursement requests on your behalf to ensure maximum compensation for any fulfillment issues.",
      },
      {
        Bold: "Return Analysis & Actionable Insights:",
        content:
          " Analyzing customer return data to provide actionable insights for minimizing returns and enhancing customer satisfaction.",
      },
    ],
    Seller: "We help you sell on Ecommerce Platforms like Amazon and Flipkart",
    Cards: [
      {
        title: "Flipkart",
        img: images.flipkart,
        description: "lorem ksdgvhlsgvidlhvvdgklfsjvh kgdfsjvdkblfjk ",
      },
      {
        title: "JioMart",
        img: images.jiomart,
        description: "lorem ksdgvhlsgvidlhvvdgklfsjvh kgdfsjvdkblfjk ",
      },
      {
        title: "Meesho",
        img: images.meesho,
        description: "lorem ksdgvhlsgvidlhvvdgklfsjvh kgdfsjvdkblfjk ",
      },
      {
        title: "Ebay",
        img: images.ebay,
        description: "lorem ksdgvhlsgvidlhvvdgklfsjvh kgdfsjvdkblfjk ",
      },
      {
        title: "Etsy",
        img: images.etsy,
        description: "lorem ksdgvhlsgvidlhvvdgklfsjvh kgdfsjvdkblfjk ",
      },
      {
        title: "Facebook Marketplace",
        img: images.facebook,
        description: "lorem ksdgvhlsgvidlhvvdgklfsjvh kgdfsjvdkblfjk ",
      },
    ],
  },
  {
    slug: "web-design-and-development",
    title: "Web Design & Development: Create Stunning Online Experiences",
    content:
      "Web design and development play a critical role in shaping the online presence and user experience of a business. A well-designed and user-friendly website not only attracts visitors but also converts them into customers. From intuitive navigation to visually appealing layouts and responsive design, effective web design and development practices can help businesses stand out in the digital landscape and drive engagement, conversions, and revenue.",
    image: images.webDesign,
    paragraphs: [
      {
        content:
          "At Growth Rocket, we specialize in creating custom web design and development solutions that align with our clients' branding, goals, and target audience. Our team of experienced designers and developers combines creativity with technical expertise to deliver stunning, functional websites that drive results. Whether you're launching a new website or redesigning an existing one, we can help you create an online experience that captivates your audience and delivers tangible business outcomes.",
      },
    ],
  },
  {
    slug: "social-media-management",
    title: "Social Media Management: Engage Your Audience & Drive Growth",
    content:
      "Social media management is essential for businesses looking to build and maintain a strong online presence. By effectively managing your social media channels, you can engage with your audience, build brand awareness, and drive growth. From content creation and scheduling to community management and analytics, strategic social media management can help businesses connect with customers, nurture relationships, and achieve their marketing goals.",
    image: images.socialMedia,
    paragraphs: [
      {
        content:
          "At Growth Rocket, we offer comprehensive social media management services to help businesses harness the power of social media for growth and success. Our team of social media experts develops tailored strategies that leverage the unique strengths of each platform to drive engagement, increase followers, and generate leads. Whether you're looking to build brand awareness, drive website traffic, or boost sales, our social media management services can help you achieve your objectives and maximize your ROI.",
      },
    ],
  },
  {
    slug: "lead-generation",
    title: "Lead Generation: Fuel Your Sales Pipeline with Qualified Leads",
    content:
      "Lead generation is the process of attracting and converting prospects into potential customers. By identifying and nurturing leads through various marketing channels, businesses can fill their sales pipeline with qualified opportunities and drive revenue growth. From content marketing and email campaigns to SEO and social media, effective lead generation strategies can help businesses connect with their target audience, build trust, and ultimately drive conversions.",
    image: images.leadGeneration,
    paragraphs: [
      {
        content:
          "At Growth Rocket, we specialize in developing customized lead generation strategies that deliver measurable results for our clients. Our team of experts utilizes a combination of inbound and outbound marketing tactics to attract, engage, and convert prospects at every stage of the buyer's journey. Whether you're looking to increase brand awareness, generate leads, or drive sales, our lead generation services can help you achieve your goals and drive sustainable growth for your business.",
      },
    ],
  },
  {
    slug: "ads-management",
    title: "Unlock the Power of Data-Driven Ads Management",
    content:
      "Analytics and tracking lie at the heart of effective ads management, empowering businesses with invaluable insights into the performance and efficacy of their advertising campaigns. By harnessing data from diverse channels and touchpoints, businesses can discern patterns, track user engagement, and gauge the impact of their ad initiatives. From scrutinizing website analytics to monitoring social media metrics and evaluating email campaign performance, strategic analytics and tracking facilitate informed decision-making, enabling businesses to fine-tune their strategies and foster continual enhancement.",
    image: images.analytics,
    paragraphs: [
      {
        content:
          "At Growth Rocket, we recognize the pivotal role of data-driven decision-making in navigating today's competitive business landscape. That's why we provide comprehensive ads management solutions, integrating advanced analytics and tracking services to furnish businesses with actionable insights into their advertising performance. Leveraging cutting-edge analytics tools and methodologies, our adept team collects, analyzes, and interprets data from disparate sources, furnishing clients with valuable insights and recommendations for enhancement. Whether you seek to optimize ad placements, refine targeting strategies, or ascertain the ROI of your campaigns, our ads management services are tailored to help you attain your business objectives and foster sustained success.",
      },
    ],
  },
];
export default {
  Menu,
  CaseStudies,
  WorkingProcess,
  Team,
  Testimonials,
  ServicesData,
  DyanmicPages,
};
